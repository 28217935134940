.setting-page-container {
    height: 90%;
    .spc-title {
        color: $theme-blue;
    }
    .spc-switch-container {
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;
        background: #e9e9e9;
        .spc-switch {
            display: flex;
            align-items: center;
            color: $theme-blue;
            padding: 10px;
            margin: 10px;
            width: 250px;
            .spc-switch-name {
                margin-left: 10px;
            }
        }
    }
    .change-password-container {
        display: flex;
        @media screen and (max-width: 598px) {
            flex-direction: column;
        }
        .change-password-form,
        .password-condition {
            width: 35%;
            @media screen and (max-width: 992px) {
                width: 50%;
            }
            @media screen and (max-width: 598px) {
                width: 100%;
            }
            .condition {
                margin: 5px 0;
                img {
                    height: 0.8em;
                    margin-right: 5px;
                }
            }
        }
        .password-condition {
            @media screen and (max-width: 598px) {
                display: none;
            }
        }
        .change-password-form {
            margin-right: 10%;
            .btn-container {
                margin-top: 30px;
                .change-password-btn {
                    padding: 0.6em 1.2em;
                    border-radius: 0.4em;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
    .account-status-card {
        background-color: $White-Color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0.4em;
        padding: 25px;

        .status-line {
            display: flex;
            align-items: center;
            .status {
                color: $theme-light-green;
            }
            .status-dot {
                width: 0.8em;
                height: 0.8em;
                border-radius: 50%;
                background-color: $theme-light-green;
                margin: 0 10px;
            }
        }

        .delete-btn {
            border-style: none;
            border-radius: 0.4em;
            background-color: $theme-carrot;
            color: $White-Color;
            padding: 0.6em 1.2em;
            margin-top: 10px;
        }
    }
    .account-resume-card {
        background-color: $White-Color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0.4em;
        padding: 8px 25px;
        min-width: 820px;
        .btn-container-autoresumeScore {
            display: flex;
            justify-content: flex-end;
            text-align: center;
            flex-direction: column;
        }
    }

    .score-line-1,
    .score-line-2 {
        display: flex;
        align-items: center;
        height: 30px;
    }

    .score-line-1-disabled {
        opacity: 0.5;
    }

    .span-line {
        font-size: 12px;
        color: #374659;
        font-weight: 500;
    }
    .resumeScore-input {
        width: 40px;
        height: 21px;
        text-align: center;
    }

    .sub-feedback-permission {
        background: #f4f4f4;
        border-radius: 0.4em;
        padding: 8px 25px;
        width: 97%;
        min-width: 820px;
        margin: 0 auto;
        margin-top: 2px;
        .auto-text {
            font-size: 12px;
        }
        transition: all linear 1s;
        .check-headers {
            position: absolute;
            display: flex;
            font-size: 12px;
            top: -37px;
            right: 20px;
        }
    }
    .sub-feedback-permission.last {
        margin-top: 40px;
    }

    .check-wrapper {
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .transition {
        transition: all linear 0.3s;
    }

    .account-panelist-slot {
        background-color: $White-Color;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0.4em;
        padding: 5px 25px;
        min-width: 820px;
        .auto-text {
            font-size: 14px;
        }
        .score-line {
            display: flex;
            align-items: center;
            height: 30px;
            gap: 10px;
        }
        .radio-label {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        .span-line {
            font-size: 12px;
            color: #374659;
            font-weight: 500;
        }

        .input-with-buttons {
            position: relative;
            display: inline-block;
        }
        .resumeScore-input {
            width: 40px;
            height: 21px;
            text-align: center;
        }
        .btn-container-panelist {
            text-align: center;
            flex-direction: column;
        }
    }

    .reset-popup {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 10000;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow-y: auto;
        .reset-clear-popup {
            display: flex;
            justify-content: center;
            background-color: white;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 24px;
            width: 400px;
            .close-btn {
                width: 12px;
                height: 12px;
                color: #667085;
                justify-content: center;
                align-items: center;
            }
            .reset-popup-title {
                font-size: 18px;
                text-align: center;
                color: #133f7d;
                margin-bottom: 25px;
            }
            .reset-popup-btn-container {
                gap: 12px;
                .cpb {
                    border: none;
                    border-radius: 8px !important;
                    font-size: 14px;
                    padding: 32px, 0px, 0px, 0px;
                    color: black;
                    width: 100%;
                    height: 44px;
                }
                .cpb-white {
                    background-color: #ffffff;
                    color: black;
                    border-color: #d0d5dd;
                    border: 1px solid;
                }
                .cpb-red {
                    background-color: #d92d20;
                    color: white;
                }
            }
        }
    }
    .mar-top-32 {
        margin-top: 32px;
    }
    .mar-top-16 {
        margin-top: 16px;
    }
    .spc-custom-container {
        .spc-custom-fields-container {
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .spc-custom-field {
                width: 48%;
                margin-bottom: 10px;
                .add-field-btn {
                    background-color: #1c4784;
                    padding: 10px 15px;
                    font-size: 13px;
                    border: none;
                    color: white;
                    margin: 10px 0;
                }

                .spc-checkbox {
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                }
            }
        }

        .spc-table-container {
            overflow-x: auto;
            width: 100%;
            padding-right: 10px;
            .spc-table-header {
                min-width: 800px;
                background-color: #e6e9f8;
                color: #626262;
                height: 50px;
            }
            .spc-table-row-container {
                min-width: 800px;
                .spc-table-row {
                    background-color: white;
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    margin: 8px 0;
                    .spc-table-row-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .cancel-field-btn {
        border: 1px solid #133f7d !important;
        padding: 10px 15px;
        background-color: transparent;
        font-size: 13px;
        border: none;
        color: #133f7d;
        margin: 10px 0;
    }
    .add-field-btn {
        background-color: #1c4784;
        padding: 10px 15px;
        font-size: 13px;
        border: none;
        color: white;
        margin: 10px 0;
    }

    .ats-integration-container {
        background-color: #ffffff;
        padding: 10px;
        position: relative;
        .ats-drop-arrow {
            position: absolute;
            top: 20px;
            right: 20px;
            height: auto;
            width: 10px;
            cursor: pointer;
        }
        .ats-drop-arrow-rotate {
            transform: rotate(90deg);
        }
        .ats-title {
            font-size: 14px;
        }
        .ats-subtitle {
            font-size: 12px;
        }
        .ats-input-container {
            display: flex;
            gap: 30px;
            .ats-input-box {
                width: 40%;
            }
        }
        .ats-btn-container {
            .generate-key-btn {
                margin-top: 10px;
                padding: 8px 10px;
                border-radius: 5px;
                background: #133f7d;
                font-size: 10px;
                color: #ffffff;
                border: none;
            }
        }
        .ats-app-btn-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
        .ats-app-add-field-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            background-color: #f0f0f0;
            border: 1px solid #123a75;
            transition: background-color 0.3s ease;
        }
        .ats-app-add-field-btn:hover {
            background-color: #333;
            color: #ffffff;
        }
        .ats-app-integration-icon {
            max-width: 100%;
            max-height: 30px;
            object-fit: contain;
        }
    }
}
.two-factor-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #374659;
}
.btn-container-two-factor {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
